<template>
  <div
    class="modal"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal_inner confirm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form_outwrap">
              <div class="modal_header">
                <p class="ttl_header">{{ "確認" }}</p>
              </div>
              <div class="modal_wrap">
                <div class="for_wrap h_80">
                  <div class="modal_contents">
                    <p>
                      <span>{{ "削除しますか？" }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal_footer">
                <div class="flex_wrap f_c">
                  <div class="btn_norm reverse" id="cancel_bk">
                    <button
                      name=""
                      value="キャンセル"
                      class="close"
                      dusk="delete_data"
                      @click="closeDiscard()"
                    >
                      キャンセル
                    </button>
                  </div>
                  <div class="btn_norm delete">
                    <button
                      @click="deleteConfirm()"
                      class="btn btn"
                      id= "delete-btn"
                      value="アーカイブ"
                      title="削除"
                      dusk="delete_data"
                    >
                      実行
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        id="close_btn"
        class="pop-remove_btn ttoltip tip-top close"
        @click="closeDiscard()"
      >
        <i class="fas fa-times"></i>
      </p>
      <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
    </div>
    <!--modal_inner-->
  </div>
  <!--modal-->
</template>

<script>
import $ from "jquery";


export default {
  name: "Delete",
  methods: {
      
      closeDiscard() {
          $("#confirmModal").removeClass("open");
        },
        deleteConfirm()
        {
            console.log("delete console");
        }
      },
 
};
</script>
